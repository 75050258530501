<template>
  <div tabindex="0" @keydown.esc="cerrar">
    <div v-if="editNombre === undefined">
      <br />
      <h1 style="text-shadow: 1px 1px 2px black; text-decoration: underline; display: block; text-align: center">MIS DICCIONARIOS</h1>
      <br />
    </div>
    <div class="menu" v-if="editNombre === undefined">
      <button @click="nuevoDiccionario" style="background: white">
        <ThemifyIcon icon="file" />
        <div>NUEVO<br />&nbsp;</div>
      </button>

      <button @click="toggle" style="background: lightblue">
        <ThemifyIcon icon="check" />
        <div>MARCAR<br />DESMARCAR</div>
      </button>

      <button @click="compile" style="background: lightgreen">
        <ThemifyIcon icon="package" />
        <div>COMPILAR<br />SELECCIÓN</div>
      </button>

      <button @click="removeAll" style="background: pink">
        <ThemifyIcon icon="trash" />
        <div>ELIMINAR<br />SELECCIÓN</div>
      </button>

      <button @click="loadCSV" style="background: lightyellow">
        <ThemifyIcon icon="import" />
        <div>IMPORTAR<br />CSV</div>
      </button>

      <button @click="loadJSON" style="background: lightyellow">
        <ThemifyIcon icon="import" />
        <div>IMPORTAR<br />JSON</div>
      </button>

      <button @click="exportCSV" style="background: lightsalmon">
        <ThemifyIcon icon="export" />
        <div>EXPORTAR<br />CSV</div>
      </button>

      <button @click="exportJSON" style="background: lightsalmon">
        <ThemifyIcon icon="export" />
        <div>EXPORTAR<br />JSON</div>
      </button>
    </div>
    <hr />
    <input type="file" multiple @change="openload" style="display: none" ref="fileJSON" accept=".json" />
    <input type="file" multiple @change="openload" style="display: none" ref="fileCSV" accept=".csv" />
    <div v-if="loading !== false" class="loading">
      <h2>{{ loading }}</h2>
    </div>
    <div style="padding: 8px" v-else>
      <div v-for="(name, idx) of diccionariosCargados" :key="idx">
        <div v-if="editNombre === undefined" class="diccionario" @click="edit(name)" style="padding: 8px; cursor: pointer; color: white">
          <label style="display: inline-block; cursor: pointer" @click.stop="" :for="'compile_' + name"><input style="cursor: pointer; width: 20px; height: 20px; margin-right: 4px" type="checkbox" :checked="checked.includes(name)" @change="changeChecked(name)" :id="'compile_' + name" :ref="'compile_' + name" /> {{ name }}</label>
          <button @click.prevent.stop="remove(name)"><ThemifyIcon icon="trash" /></button>
          <button @click.prevent.stop="clonar(name)"><ThemifyIcon icon="files" /></button>
        </div>

        <div v-if="name === editNombre" class="head-edit">
          <span style="color: white; margin-top: 4px; font-weight: bolder; font-size: 15px">Diccionario </span>
          <input style="width: calc(100% - 196px) !important" v-model="cambiarNombre" />
          <div style="display: inline-block; top: 5px; position: relative; width: 96px; height: 27px; z-index: 900">
            <button @click="cerrar" title="CERRAR"><ThemifyIcon icon="close" /></button>
            <button @click="guardar"><ThemifyIcon icon="save" /></button>
            <button @click="sort"><ThemifyIcon icon="list-ol" /></button>
          </div>
          <div style="display: grid; grid-template-columns: 60px 1fr 28px 28px; padding-right: 8px">
            <input @input="filtrar($event.target.value.toUpperCase())" style="width: calc(100% + 6px) !important;text-transform: uppercase; column;grid-column: span 4;margin:4px 4px 8px 0 ; padding: 6px 6px 2px;margin-bottom:0" v-model="filtro" placeholder="🔎 Filtro de busqueda" />
          </div>
        </div>

        <div v-if="name === editNombre" class="diccionario">
          <div class="edit-control" style="color: white; background: rgba(0, 0, 0, 0.5)">
            <div style="display: inline-block" v-if="edicionSize > 0">
              <button title="PAGINA ANTERIOR" @click="edicionPaginar(-edicionSize)"><ThemifyIcon icon="angle-left" /></button>
              {{ Math.round(1 + edicionBegin / edicionSize) }} / {{ Math.round(edicion.length / edicionSize) }}
              <button title="PAGINA SIGUIENTE" @click="edicionPaginar(edicionSize)"><ThemifyIcon icon="angle-right" /></button>
              <button style="margin-left: 12px" @click="nuevo" v-if="filtro === ''" title="NUEVO REGISTRO"><ThemifyIcon icon="plus" /></button>
            </div>
            <label style="float: right; font-size: 10px; height: 10px; line-height: 15px; padding: 0 0 0 4px">Palabras<br />Visibles</label>
            <input title="REGISTROS POR PAGINA" @change="storePagination" style="width: 44px; font-size: 11px; float: right; color: black; text-align: center; padding: 5px 0 2px" type="number" v-model.number="edicionSize0" min="0" max="100" />
            <hr />
          </div>

          <div class="edit">
            <div v-for="(item, idx2) of edicionPagination" :key="item[item.length - 1]" class="scrollX" style="margin: 6px 0; padding: 8px; background: rgba(0, 0, 0, 0.25); border-radius: 8px; border: 2px dotted black">
              <button class="plusIcon" tabindex="-1" @click="borrarItem(item[item.length - 1])" style="position: absolute; left: -4px">
                <ThemifyIcon icon="trash" style="background: red; color: black; font-weight: bolder; border-radius: 12px; border: 3px solid black" />
              </button>
              <span @click="clicFocus($event)" v-for="(i, idx) of item" :key="updateKey + '' + item[item.length - 1] + '_' + idx" :style="idx === 0 ? { background: check(item[item.length - 1]) ? 'black' : 'red', border: filtro.trim().length > 0 && typeof i === 'string' && i.indexOf(filtro) > -1 ? '2px solid yellow !important' : '1px dotted black' } : { display: idx < item.length - 1 ? 'inline' : 'none', background: 'rgba(0,0,0,.15)', marginLeft: '1px', border: filtro.trim().length > 0 && typeof i === 'string' && i.indexOf(filtro) > -1 ? '2px solid yellow !important' : '1px dotted black' }">
                <span v-if="idx < item.length - 1" tabindex="0" @click="clicEdit" contenteditable @keydown="checkEnter($event)" @input="change(item[item.length - 1], idx, $event.target)">{{ i }}</span>
                <button v-if="idx > 0" class="closeIcon" tabindex="-1" @click="left(item[item.length - 1], idx)">
                  <ThemifyIcon icon="angle-double-left" :style="idx === 0 ? { color: 'white' } : {}" />
                </button>
                <button v-if="idx < item.length - 2" class="closeIcon" tabindex="-1" @click="right(item[item.length - 1], idx)">
                  <ThemifyIcon icon="angle-double-right" :style="idx === 0 ? { color: 'white' } : {}" />
                </button>
                <button class="closeIcon" v-if="idx < item.length - 1" tabindex="-1" @click="borra(item[item.length - 1], idx, idx2)">
                  <ThemifyIcon icon="trash" :style="idx === 0 ? { color: 'white' } : {}" />
                </button>
              </span>
              <button class="plusIcon" tabindex="-1" @click="plus(item[item.length - 1], $event)">
                <ThemifyIcon icon="plus" :style="idx === 0 ? { color: 'white' } : {}" />
              </button>
            </div>
          </div>
          <div v-if="edicionSize > 0" class="edit-control" style="color: white; margin-bottom: 10px; background: rgba(0, 0, 0, 0.5)">
            <button @click="edicionPaginar(-edicionSize)"><ThemifyIcon icon="angle-left" /></button>
            {{ Math.round(1 + edicionBegin / edicionSize) }} / {{ Math.round(edicion.length / edicionSize) }}
            <button @click="edicionPaginar(edicionSize)"><ThemifyIcon icon="angle-right" /></button>
          </div>
          <br />
        </div>
        <div style="clear: both"></div>
      </div>
    </div>
  </div>
</template>
<script>
import ConwordsGenerator from '../crucigramas/ConwordsGenerator';
import ThemifyIcon from 'vue-themify-icons';
import clone from 'rfdc/default';
import JSZip from 'jszip';
let lastStore = 0;
let lastCheck = '';
export default {
  name: 'Diccionario',
  components: {
    ThemifyIcon,
  },
  data() {
    return {
      audio: 1,
      checked: [],
      csvSeparator: ',',
      filtro: '',
      editNombre: undefined,
      cambiarNombre: undefined,
      edicionBegin: 0,
      edicionSize0: 4,
      edicionSize: 4,
      edicion: [],
      loading: false,
      diccionariosCargados: [],
      undo: [],
      redo: [],
      updateKey: 0,
    };
  },
  computed: {
    uid: function () {
      return this.$store.state.credentials.uid;
    },
    edicionPagination: function () {
      return clone(
        (this.filtro.trim() === ''
          ? this.edicion
          : this.edicion.filter((arr) => {
              let found = false;
              for (let i = 0; i < arr.length - 1; i++) {
                if (arr[i].indexOf(this.filtro) > -1) {
                  found = true;
                  break;
                }
              }
              return found;
            })
        ).slice(this.edicionBegin, this.edicionBegin + this.edicionSize)
      );
    },
  },
  watch: {
    edicionSize0: function (val) {
      val = val ? val : 0;
      if (val >= 0 && val <= 100) {
        this.edicionSize = val;
      }
    },
    uid: function () {
      this.audio = this.getLocal('audio') === '1';
      this.setLocal('csvSeparator', this.csvSeparator);
    },
    filtro: function () {
      this.edicionBegin = 0;
    },
    csvSeparator: function () {
      this.setLocal('csvSeparator', this.csvSeparator);
    },
  },
  mounted() {
    this.audio = this.getLocal('audio') === '1';
    this.csvSeparator = this.getLocal('csvSeparator') || this.csvSeparator;
    if (this.db()) {
      let transaction = this.db().transaction('diccionarios', 'readonly');
      let diccionarios = transaction.objectStore('diccionarios');
      this.loading = 'Cargando diccionarios...';
      let list = diccionarios.getAll();
      transaction.oncomplete = () => {
        list.result.forEach((diccionario) => {
          this.diccionariosCargados.push(diccionario.nombre);
        });
        this.loading = false;
      };
    }
    this.setLoading(false);
  },

  methods: {
    changeChecked(name) {
      if (this.checked.indexOf(name) > -1) {
        this.checked.splice(this.checked.indexOf(name), 1);
      } else {
        this.checked.push(name);
      }
    },
    check(idx) {
      //console.log('check', idx, this.edicion[idx], this.edicion[idx].length);
      let resp = false;
      // console.log(idx, typeof idx === 'number', ('' + idx).match(/\d+/));
      if (typeof idx === 'number' && this.edicion[idx].length > 2) {
        const match = this.edicion[idx][0].match(/^[A-ZÑÁÉÍÓÚÄËÏÖÜ]+/g);
        resp = match !== null && match[0] == this.edicion[idx][0] && this.edicion[idx][0].length > 1;
        if (resp) {
          for (let i = 1; i < this.edicion[idx].length - 1; i++) {
            // console.log(this.edicion[idx][i], '[^A-ZÑÁÉÍÓÚÄËÏÖÜ]' + this.edicion[idx][0] + '[^A-ZÑÁÉÍÓÚÄËÏÖÜ]');
            if ((' ' + this.edicion[idx][i] + ' ').match(new RegExp('[^A-ZÑÁÉÍÓÚÄËÏÖÜ]' + this.edicion[idx][0] + '[^A-ZÑÁÉÍÓÚÄËÏÖÜ]')) !== null) {
              let msg = `Una respuesta contiene la palabra  ${this.edicion[idx][0]} `;
              if (lastCheck !== msg + idx) {
                this.notification(msg);
                lastCheck = msg + idx;
              }
              resp = false;
              break;
            }
          }
        }
      }
      return resp;
    },
    filtrar(text) {
      const STORE_DELAY = 500;
      const now = Date.now();
      lastStore = now;
      setTimeout(() => {
        if (now === lastStore) {
          this.filtro = text;
        }
      }, STORE_DELAY);
    },
    delay: async (delay) => {
      return new Promise((resolve) => {
        setTimeout(resolve, delay);
      });
    },
    borra: function (idx0, idx1, idx2) {
      this.edicion[idx0].splice(idx1, 1);
      if (this.edicion[idx0].length === 1) {
        this.borrarItem(idx0);
      } else {
        const query = `.edit div:nth-child(${idx2 + 1}) span span`;
        const el = document.querySelector(query);
        // console.log(el, idx2);
        //this.change(idx0, 0, el);
      }
    },
    change(idx0, idx1, e) {
      //console.log('change', idx0, idx1, e.innerHTML);
      let txt = e.innerHTML.replace(/\s+/, ' ').toUpperCase();
      this.edicion[idx0][idx1] = txt;
      if (idx1 === 0) {
        e.parentNode.style.background = this.check(idx0) ? 'black' : 'red';
      } else {
        e.parentNode.parentNode.children[1].style.background = this.check(idx0) ? 'black' : 'red';
      }
    },
    nuevo: function () {
      this.edicion.splice(this.edicionBegin, 0, ['', this.edicionBegin]);
      for (let i = this.edicionBegin + 1; i < this.edicion.length; i++) {
        this.edicion[i][this.edicion[i].length - 1] = i;
      }
      setTimeout(() => {
        let newEdit = document.querySelector('span:nth-child(2n) > span');
        if (newEdit) {
          newEdit.innerHTML = '';
          newEdit.focus();
          lastCheck = '';
          this.$forceUpdate();
        }
      }, 100);
    },
    checkEnter(evt) {
      if (evt.keyCode === 13) {
        evt.preventDefault();
      }
      //check ctrl+s
      if (evt.ctrlKey && evt.keyCode == 83) {
        evt.preventDefault();
        this.guardar();
      }
    },
    storePagination: function () {
      const newedicionBegin = Math.round(this.edicionBegin / this.edicionSize) * this.edicionSize;
      if (!isNaN(newedicionBegin)) {
        this.edicionBegin = Math.max(0, newedicionBegin);
      }
      this.setLocal('.' + this.editNombre + '.edicionBegin', this.edicionBegin);
      this.setLocal('.' + this.editNombre + '.edicionSize', this.edicionSize);
    },
    edicionPaginar: function (num) {
      this.edicionBegin += num;
      if (this.edicionBegin < 0) {
        this.edicionBegin = 0;
        lastCheck = '';
      }
      if (this.edicionBegin > this.edicion.length - this.edicionSize) {
        this.edicionBegin = this.edicion.length - this.edicionSize;
        lastCheck = '';
      }
      this.storePagination();

      let el = document.querySelector('html');
      el.scrollTop = 0;
      document.querySelectorAll('.scrollX').forEach((e) => {
        e.scrollLeft = 0;
      });
    },
    guardar: function () {
      if (this.cambiarNombre !== this.editNombre && this.diccionariosCargados.includes(this.cambiarNombre)) {
        this.notification(`Ya existe un diccionario con el nombre ${this.cambiarNombre}, elije otro nombre`);
        this.cambiarNombre = this.editNombre;
        return;
      }
      if (this.cambiarNombre !== this.editNombre) {
        this.remove(this.editNombre, false);
        this.checked[this.checked.indexOf(this.editNombre)] = this.cambiarNombre;
        this.notification(`Se ha cambiado el nombre del diccionario de '${this.editNombre}' a '${this.cambiarNombre}'`);
      }
      this.setStorage(
        this.cambiarNombre,
        this.edicion.map((arr) => arr.slice(0, arr.length - 1))
      );
      this.notification('Se ha guardado el diccionario');
      this.editNombre = this.cambiarNombre;
      this.storePagination();
    },

    left: function (idx0, idx1) {
      if (idx1 > 0) {
        let txt = this.edicion[idx0][idx1 - 1];
        let txt1 = this.edicion[idx0][idx1];
        this.updateKey++;
        this.$set(this.edicion[idx0], idx1, txt);
        this.$set(this.edicion[idx0], idx1 - 1, txt1);
      }
    },
    right: function (idx0, idx1) {
      if (idx1 < this.edicion[idx0].length - 1) {
        let txt = this.edicion[idx0][idx1 + 1];
        let txt1 = this.edicion[idx0][idx1];
        this.updateKey++;
        this.$set(this.edicion[idx0], idx1, txt);
        this.$set(this.edicion[idx0], idx1 + 1, txt1);
      }
    },
    plus: function (idx0, evt) {
      let edit = clone(this.edicion[idx0]);
      edit.splice(edit.length - 1, 0, '');
      this.updateKey++;
      this.$set(this.edicion, idx0, edit);
      const children = evt.target.parentElement.parentElement.children;
      setTimeout(() => {
        children[children.length - 3].children[0].focus();
        lastCheck = '';
      }, 100);
    },
    borrarItem: function (idx0) {
      this.edicion.splice(idx0, 1);
      for (let i = idx0; i < this.edicion.length; i++) {
        this.edicion[i][this.edicion[i].length - 1] = this.edicion[i][this.edicion[i].length - 1] - 1;
      }
      if (this.edicion.length > idx0) {
        const val = this.edicion[idx0][0];
        const pos = idx0 - this.edicionBegin;
        setTimeout(() => {
          const query = `.edit div:nth-child(${pos + 1}) span span`;
          const el = document.querySelector(query);
          //console.log(el?.innerHTML, val, query);
          if (el) {
            el.innerHTML = val;
            //console.log(el.innerHTML, val);
          }
        }, 100);
      }
    },
    toggle: function () {
      //toggle all diccionarios
      if (this.checked.length === this.diccionariosCargados.length) {
        this.checked = [];
      } else {
        this.checked = clone(this.diccionariosCargados);
      }
    },
    removeAll: function () {
      this.checked.forEach((dic) => {
        this.remove(dic, false);
      });
      this.checked = [];
    },
    compile: function () {
      let names = [];
      this.diccionariosCargados.forEach((nombre) => {
        if (this.$refs['compile_' + nombre][0].checked) {
          names.push(nombre);
        }
      });
      let diccionarios = [];
      if (names.length > 0) {
        this.loading = 'Compilando diccionarios...';
        let db = this.db();
        let transaction = db.transaction('diccionarios', 'readonly');
        let diccs = transaction.objectStore('diccionarios');
        let list = diccs.getAll();
        transaction.oncomplete = async () => {
          list.result.forEach((diccionario) => {
            if (names.includes(diccionario.nombre)) {
              diccionarios.push(diccionario);
            }
          });
          let este = this;
          let comp = await ConwordsGenerator.compilar(
            diccionarios.map((e) => e.data),
            async function (progress) {
              este.loading = 'Compilando diccionarios... ' + progress + '%';
            }
          );
          transaction = db.transaction('compilacion', 'readwrite');
          let store = transaction.objectStore('compilacion');

          store.put({
            nombre: 'compilacion',
            compilacion: comp,
          });
          transaction.oncomplete = () => {
            this.loading = false;
          };
        };
      }
    },
    cerrar: function () {
      this.edicion = [];
      this.editNombre = undefined;
    },
    sort: function () {
      this.edicion.sort((a, b) => {
        if (a[0] < b[0]) {
          return -1;
        }
        if (a[0] > b[0]) {
          return 1;
        }
        return 0;
      });
    },
    clicFocus(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      if (evt.target.children[0]) {
        evt.target.children[0].focus();
        let range = document.createRange();
        let sel = window.getSelection();
        range.setStart(evt.target.children[0], evt.target.children[0].childNodes.length);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    },
    clicEdit: function (evt) {
      let contentEditable = evt.target;
      if (contentEditable.innerHTML === '') {
        contentEditable.innerHTML = ' ';
        let sel, range;
        if (window.getSelection && document.createRange) {
          range = document.createRange();
          range.selectNodeContents(contentEditable);
          sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
        } else if (document.body.createTextRange) {
          range = document.body.createTextRange();
          range.moveToElementText(contentEditable);
          range.select();
        }
      }
    },
    edit: function (nombre) {
      this.filtro = '';
      let val = this.getLocal('.' + nombre + '.edicionSize');
      if (val) {
        this.edicionSize = parseInt(val);
        this.edicionSize0 = parseInt(val);
      }
      val = this.getLocal('.' + nombre + '.edicionBegin');
      this.edicionBegin = Math.max(0, Math.round(parseInt(val !== null ? val : 0) / this.edicionSize) * this.edicionSize);
      if (this.editNombre === nombre) {
        this.editNombre = undefined;
        this.cambiarNombre = undefined;
        this.edicion = [];
      } else {
        this.editNombre = nombre;
        this.cambiarNombre = nombre;
        this.loading = 'Cargando diccionario...';
        let transaction = this.db().transaction('diccionarios');
        let diccionarios = transaction.objectStore('diccionarios');
        let list = diccionarios.get(nombre);
        transaction.oncomplete = () => {
          this.edicion = list.result.data.map((item, idx) => {
            return [...item, idx];
          });
          this.loading = false;
        };
      }
    },
    remove: function (nombre, ask = true) {
      const action = () => {
        let transaction = this.db().transaction('diccionarios', 'readwrite');
        let diccionarios = transaction.objectStore('diccionarios');
        let request = diccionarios.delete(nombre);
        request.onsuccess = () => {
          this.removeLocal('.' + nombre + '.edicionBegin');
          this.diccionariosCargados.splice(this.diccionariosCargados.indexOf(nombre), 1);
          if (ask) {
            this.notification('Diccionario eliminado ' + nombre);
            this.checked.splice(this.checked.indexOf(nombre), 1);
          }
        };
      };
      if (ask) {
        this.confirm("Confirme para borrar el diccionario '" + nombre + "'", action);
      } else {
        action();
      }
    },
    clonar: function (nombre) {
      let count = 1;
      while (this.diccionariosCargados.includes(nombre + '.' + count)) {
        count++;
      }
      let transaction = this.db().transaction('diccionarios');
      let diccionarios = transaction.objectStore('diccionarios');
      let list = diccionarios.get(nombre);
      transaction.oncomplete = () => {
        let data = list.result.data;
        let transaction = this.db().transaction('diccionarios', 'readwrite');
        let diccionarios = transaction.objectStore('diccionarios');
        nombre = nombre + '.' + count;
        diccionarios.add({ nombre, data });
        transaction.oncomplete = () => {
          this.diccionariosCargados.push(nombre);
          this.diccionariosCargados = this.diccionariosCargados.sort();
          this.notification('Diccionario Clonado ' + nombre);
        };
      };
    },
    nuevoDiccionario: function () {
      this.prompt('Nombre del diccionario', (nombre) => {
        let transaction = this.db().transaction('diccionarios', 'readwrite');
        let diccionarios = transaction.objectStore('diccionarios');
        diccionarios.add({ nombre, data: [] });
        transaction.oncomplete = () => {
          this.diccionariosCargados.push(nombre);
          this.diccionariosCargados = this.diccionariosCargados.sort();
          this.notification('Diccionario creado ' + nombre);
        };
      });
    },

    exportCSV: function () {
      const zip = new JSZip();
      let zips = 0;
      for (let nombre of this.checked) {
        let transaction = this.db().transaction('diccionarios');
        let diccionarios = transaction.objectStore('diccionarios');
        let list = diccionarios.get(nombre);
        let checkedCount = this.checked.length;
        const csvSep = this.csvSeparator;
        transaction.oncomplete = function () {
          let diccionario = list.result.data;
          let txt = diccionario.map((words) => words.map((w) => `"${w.replace(/\"/g, '""')}"`).join(csvSep === 'TAB' ? '\t' : csvSep)).join('\n');
          zip.file(nombre + '.csv', txt);
          zips++;
          if (zips === checkedCount) {
            const filename = 'diccionarios.csv.zip';
            zip.generateAsync({ type: 'blob' }).then(function (blob) {
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, filename);
              } else {
                let a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                a.download = filename;
                a.click();
              }
            });
          }
        };
      }
    },
    exportJSON: function () {
      const zip = new JSZip();
      let zips = 0;
      for (let nombre of this.checked) {
        let transaction = this.db().transaction('diccionarios');
        let diccionarios = transaction.objectStore('diccionarios');
        let list = diccionarios.get(nombre);
        let checkedCount = this.checked.length;
        transaction.oncomplete = function () {
          let diccionario = list.result.data;
          let txt = `[\n${diccionario.map((words) => '\t' + JSON.stringify(words)).join(',\n')}\n]`;
          zip.file(nombre + '.json', txt);
          zips++;
          if (zips === checkedCount) {
            const filename = 'diccionarios.json.zip';
            zip.generateAsync({ type: 'blob' }).then(function (blob) {
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, filename);
              } else {
                let a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                a.download = filename;
                a.click();
              }
            });
          }
        };
      }
    },
    saveAs: function (blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        let a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = filename;
        a.click();
      }
    },
    loadJSON: async function () {
      this.$refs.fileJSON.click();
    },
    loadCSV: async function () {
      this.$refs.fileCSV.click();
    },
    setStorage: function (name, data) {
      let exists = this.diccionariosCargados.includes(name);
      if (!exists) {
        this.diccionariosCargados.push(name);
        this.diccionariosCargados = this.diccionariosCargados.sort();
      }
      if (this.db() !== undefined) {
        let transaction = this.db().transaction('diccionarios', 'readwrite'); // (1)
        let store = transaction.objectStore('diccionarios');
        if (!exists) {
          store.add({ nombre: name, data: data }); // (2)
        } else {
          store.put({ nombre: name, data: data }); // (2)
        }
        transaction.oncomplete = function () {
          //console.log('Transacción completa');
        };
      }
    },
    clearInputFile: (f) => {
      if (f.value) {
        try {
          f.value = ''; //for IE11, latest Chrome/Firefox/Opera...
        } catch (err) {}
        if (f.value) {
          //for IE5 ~ IE10
          var form = document.createElement('form'),
            parentNode = f.parentNode,
            ref = f.nextSibling;
          form.appendChild(f);
          form.reset();
          parentNode.insertBefore(f, ref);
        }
      }
    },
    openload: async function (e) {
      [...e.target.files].forEach((file) => {
        this.clearInputFile(e.target);
        const reader = new FileReader();
        reader.onload = async (e) => {
          let ext = file.name.split('.').pop();
          let name = file.name.substring(0, file.name.length - ext.length - 1);
          if (ext === 'json') {
            this.setStorage(name, JSON.parse(e.target.result));
          } else if (ext === 'csv') {
            let data = e.target.result.split('\n').map((line) => {
              return line
                .substring(1, line.length - 1)
                .split('"' + (this.csvSeparator === 'TAB' ? '\t' : this.csvSeparator) + '"')
                .map((s) => s.replace(/\"\"/g, '"'));
            });
            this.setStorage(name, data);
          }
        };
        reader.readAsText(file);
      });
    },
  },
};
</script>
<style scoped>
.edit-control {
  width: 100%;
  display: block;
  padding: 8px;
  border-radius: 8px;
  margin-top: 12px;
}
.edit-control button {
  float: none !important;
  display: inline-block;
  position: relative;
  top: 0rem !important;
}
.edit div > span {
  border-radius: 0.45rem;
  background: var(--color-select);
  margin: 2px 4px 0 0;
  padding: 5px;
  white-space: normal;
  box-shadow: rgba(0, 0, 0, 0.7) 4px 0px 1px 0px inset, rgba(0, 0, 0, 0.7) -4px 0px 4px 0px inset;
}

.edit div > span > span:first-child {
  margin-right: 8px;
}

.plusIcon > * {
  padding: 3px 3px 3px;
  margin: 1px;
  font-size: 18px;
  border-radius: 4px;
  background: black;
  color: white;
  line-height: 1.7rem;
  border: 1px dotted black;
}
.plusIcon {
  margin: 0 5px 0 0 !important;
  padding: 0 !important;
  width: 24px !important;
  position: relative !important;
  top: 4px !important;
}

.edit div > span > span {
  outline: none;
  padding: 3px 4px;
  line-height: 32px;
  border-radius: 6px;
  background: #fff;
  font-weight: bolder;
  text-transform: uppercase !important;
}

.edit div > span > span:focus {
  background: yellow;
}
.edit {
  clear: both;
}

.edit button {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  float: none !important;
  display: inline-block;
}
.edit span {
  color: black;
}
.edit span button {
  position: relative;
}

hr {
  clear: both;
}
input[type='checkbox'] {
  display: inline-block;
  width: auto;
  top: 0.2rem;
  right: -0.2rem;
  position: relative;
}
.loading {
  padding: 2rem;
  text-align: center;
}
.loading h2 {
  font-size: 2rem;
}
button {
  background: var(--color-select);
  width: 90px;
  margin-left: 0;
  margin-right: 0.25rem;
  font-size: 0.75rem;
  float: right !important;
}

.head-edit {
  padding: 2px 8px 8px;
  position: sticky;
  top: 38px;
  z-index: 1000;
  background: var(--color-texto);
}
.diccionario button,
.head-edit button {
  width: 26px;
  top: -2px;
  position: relative;
}

.head-edit button {
  top: 4px;
  height: 27px;
}

.diccionario label {
  display: block;
}

.diccionario {
  display: block;
  padding: 0.5rem 0.5rem 0.1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  /* text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1), 1px 1px 1px rgba(0, 0, 0, 0.5); */
  background: url(/img/bg0.png) repeat, linear-gradient(1deg, var(--color-texto), var(--color-gradient) 40px);
}
label,
input {
  cursor: text;
}

.closeIcon {
  cursor: pointer;
  width: 24px !important;
}
.closeIcon > * {
  border-radius: 0.33rem;
  font-size: 18px;
  padding: 0.25rem 0.24rem 0.23rem 0.23rem;
  margin: 0 !important;
  color: white;
  top: 6px;
  position: relative;
}
.menu {
  z-index: 1000;
  position: sticky;
  top: 34px;
  background: var(--color-texto);
  padding: 0.25rem 1rem 0.15rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  column-gap: 8px;
  row-gap: 8px;
}

@media (max-width: 920px) {
  .menu {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 512px) {
  .menu {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 340px) {
  .menu {
    grid-template-columns: 1fr 1fr;
  }
}

.menu button {
  width: 100%;
  padding: 0;
  font-size: 10px;
  margin-bottom: 10px;
}
.menu button i {
  position: relative;
  top: -4px;
  right: 3px;
}

.menu button div {
  font-size: 10px;
  text-align: center;
  display: absolute;
  width: 70px;
  display: inline-block;
}
</style>
